import React from 'react';
import { graphql } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout.js';
import SEO from '../../components/seo.js';
import PageHeading from '../../components/page-heading.js';
import '../../styles/article.scss';
import '../../styles/case-study.scss';
import Contact from '../../components/contact.js';
import Video from '../../assets/clients/beyond-nine/promo-video.mp4';

const CaseStudy = ({data}) => {
    return (
        <Layout background="stone">
            <SEO 
              title="Beyond Nine: A reimagined Shopify Store" 
              description="Discover how Beyond Nine transformed their Shopify store with a custom redesign, improving site speed by 57%, and increased revenue per user by 26%. Read the full case study."
              image="../assets/clients/beyond-nine/spring-2025.png"
              article={true}/>
            <PageHeading h1="Beyond Nine: A reimagined Shopify Store" h2="Case Study" blurb="Discover how Beyond Nine transformed their Shopify store with a custom redesign, improving site speed by 57%, and increased revenue per user by 26%. Read the full case study." />
            <section className="ff article">
            <GatsbyImage image={data.hero.childImageSharp.gatsbyImageData} alt="Beyond Nine Spring 2025" className='case-study-hero' />
                <h3>Overview</h3>
                <p>Beyond Nine is more than just a fashion brand—it’s a movement. Built on the philosophy that women’s bodies undergo incredible transformations throughout life, Beyond Nine creates stylish, comfortable, and versatile clothing that adapts to every stage of womanhood. From pregnancy to postpartum and beyond, their designs allow women to feel confident without compromise.</p>
                <p>While Beyond Nine’s vision resonated deeply with its audience, their website did not. Their existing Shopify store was built on an off-the-shelf theme that no longer aligned with their evolving brand identity and business needs. Issues with visual consistency, site performance, and user experience were starting to impact conversions, revenue per user, and search visibility. To continue growing and scaling, Beyond Nine needed a digital storefront as seamless and adaptable as their clothing.</p>
                <h3>Challenges</h3>
                <p>Beyond Nine faced several ecommerce challenges that required tailored solutions. Key obstacles included:</p>
                <ul className="numbered">
                    <li><strong>Outdated Theme:</strong> The existing Shopify theme lacked flexibility and was restricting brand expression, storytelling, and overall user experience.</li>
                    <li><strong>Performance Issues:</strong> Slow load times and poor mobile optimization were leading to higher bounce rates and lost conversions.</li>
                    <li><strong>SEO Limitations:</strong> The site’s technical structure was affecting its search engine visibility, reducing organic reach.</li>
                    <li><strong>Brand Cohesion:</strong> The website design failed to reflect the high-quality, fashion-forward identity of Beyond Nine.</li>
                </ul>
                <h3>Objectives</h3>
                <p>The project aimed to:</p>
                <ul className="numbered">
                    <li>Redesign and develop a fully custom Shopify site to align with Beyond Nine’s brand.</li>
                    <li>Improve website performance for better user experience and higher conversion rates.</li>
                    <li>Optimise the site’s technical SEO to increase organic visibility.</li>
                    <li>Ensure a seamless and scalable ecommerce experience.</li>
                </ul>
                <GatsbyImage image={data.design.childImageSharp.gatsbyImageData} alt="Beyond Nine Figma webdesign" className='case-study-image' />
                <h3>Launch Service</h3>
                <p>We implemented our structured ‘Launch’ process, which included six key stages:</p>
                <h4>1. Audience Discovery</h4>
                <p>Before designing anything, we needed to deeply understand Beyond Nine’s customers. Through data analysis, user surveys, and interviews, we identified key customer personas and their behaviors. This allowed us to design a site that truly resonated with their target audience, focusing on intuitive navigation, seamless shopping experiences, and a content structure that spoke to their needs.</p>
                <h4>2. Concepts & Mood Boarding</h4>
                <p>Aesthetics are critical in the fashion industry. We collaborated with the Beyond Nine team to define a clear visual direction through mood boards, style guides, and wireframes. The goal was to craft a digital experience that embodied Beyond Nine’s effortless, modern, and body-positive brand ethos while maintaining a high-end, editorial feel.</p>
                <h4>3. Design</h4>
                <p>We designed a bespoke Shopify theme that prioritized both aesthetics and functionality. Key features included:</p>
                <ul className="numbered">
                    <li>A clean, editorial-style layout that highlighted Beyond Nine’s storytelling and values.</li>
                    <li>High-quality product imagery and lifestyle photography to enhance engagement.</li>
                    <li>Enhanced filtering and search options to make product discovery seamless.</li>
                    <li>Optimised mobile experience for users often browsing one-handed.</li>
                </ul>
                <h4>4. Development</h4>
                <p>With the designs approved, our development team built a fully custom Shopify theme from the ground up. The focus was on:</p>
                <ul className="numbered">
                    <li><strong>Performance-driven development:</strong> Clean, lightweight code to ensure fast load speeds.</li>
                    <li><strong>Scalability:</strong> A flexible framework that would allow Beyond Nine to grow without restrictions.</li>
                    <li><strong>Conversion-focused optimisations:</strong> A streamlined checkout process and strategically placed CTAs.</li>
                    <li><strong>Third-party integrations:</strong> Seamless integration with Shopify apps, email marketing tools, and analytics platforms.</li>
                </ul>
                <h4>5. Technical SEO Optimisation</h4>
                <p>To improve Beyond Nine’s organic reach, we implemented advanced SEO strategies, including:</p>
                <ul>
                    <li><strong>Optimised site structure and internal linking</strong> for better crawlability</li>
                    <li><strong>Faster page load speeds</strong> to improve rankings and user experience</li>
                    <li><strong>Schema markup</strong> to enhance search visibility and product discoverability.</li>
                    <li><strong>Keyword-optimised content</strong> on key pages to boost search performance.</li>
                </ul>
                <h4>6. Launch</h4>
                <p>A smooth transition was crucial to ensure business continuity. We provided:</p>
                <ul>
                    <li><strong>Comprehensive training</strong> to ensure the Beyond Nine team could manage the site effectively.</li>
                    <li><strong>Post-launch monitoring and troubleshooting</strong> to ensure a flawless experience.</li>
                    <li><strong>Data analysis and insights</strong> to optimise performance post-launch.</li>
                </ul>
                <video  
                    playsInline
                    autoPlay
                    muted
                    loop
                    className="case-study-video">
                    <source src={Video} type="video/mp4" />
                    <track kind="captions" srcLang="en" />
                </video>
                <h3>Results</h3>
                <p>The Beyond Nine Shopify store launch was a resounding success. Key outcomes included:</p>
                <ul>
                    <li><strong>Site speed improvement</strong> by 57%.</li>
                    <li><strong>Revenue per user increase</strong> by 26%.</li>
                    <li><strong>Improved overall conversion rate</strong> by 26%.</li>
                    <li><strong>Enhanced organic search rankings</strong> through improved content and keyword targeting.</li>
                </ul>
                <h3>Conclusion</h3>
                <p>Beyond Nine’s journey from an underperforming, restrictive Shopify theme to a high-performing, visually stunning eCommerce experience is a testament to the power of a well-executed digital strategy. By aligning design, development, and SEO, we helped them create a platform that not only meets their current needs but is also built for future growth.</p>
                <p>If your fashion brand is struggling with an outdated Shopify theme, slow site speed, or limited SEO performance, let’s talk. We specialize in creating high-converting, performance-driven websites tailored to your brand’s unique identity.</p>
                <p><strong>Let’s build something great together.</strong></p>
            </section>
            <Contact heading="Get in touch" subheading="Could your store do with a makeover whilst making it work harder for you? Let’s chat!" />
        </Layout>
    )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "clients/beyond-nine/spring-2025.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    design: file(relativePath: { eq: "clients/beyond-nine/design.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    development: file(relativePath: { eq: "clients/beyond-nine/development.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default CaseStudy